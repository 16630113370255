import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

// import Navbar from './components/Navbar';
// import Learning from './Learning';
// import SignToText from './SignToText';
// import Login from './Login';


function SignBridge() {
  return (
    <p>This page is in progress!</p>
  );
}

export default SignBridge;
